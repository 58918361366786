import React from 'react'
import { Link } from 'gatsby'
import tw, { styled } from 'twin.macro'
import { motion } from 'framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { css, jsx } from '@emotion/core'
import {
  faLinkedin,
  faTwitter,
  faGithub,
} from '@fortawesome/free-brands-svg-icons'

// class="
const Nav = styled.nav`
  ${tw`font-sans justify-between items-center flex h-10 text-lg my-12 align-baseline`}
  max-height: 200px;
  line-height: 2.5rem;
`
const TextBold = styled.p`
  ${tw`font-bold text-3xl align-bottom inline`}
  text-align: bottom;
`
const LinkWrapper = styled.div`
  ${tw`flex justify-between items-end `}
`
// class="
const StyledLink = styled.a`
  ${tw`h-6 ml-8`}
`
const TextLink = styled.p`
  ${tw`hover:text-blue ml-8`}
`

export default () => (
  <Nav>
    <LinkWrapper>
      <Link to="/">
        <TextBold>wllnu.</TextBold>
      </Link>
      {/* <Link to="/projects">
        <TextLink>projects</TextLink>
      </Link> */}
      {/* <Link to="/cv">
        <TextLink>CV</TextLink>
      </Link> */}
      {/* <Link to="/about">
        <TextLink>about</TextLink>
      </Link> */}
    </LinkWrapper>

    <LinkWrapper>
      <StyledLink target="_blank" href="https://www.linkedin.com/in/willi-n/">
        <FontAwesomeIcon icon={faLinkedin} size="2x" />
      </StyledLink>
      <StyledLink target="_blank" href="https://github.com/willi-nuechterlein">
        <FontAwesomeIcon icon={faGithub} size="2x" />
      </StyledLink>
      <StyledLink target="_blank" href="https://twitter.com/wausber">
        <FontAwesomeIcon icon={faTwitter} size="2x" />
      </StyledLink>
    </LinkWrapper>
  </Nav>
)
