import React from 'react'
import tw, { styled } from 'twin.macro'
import { Link } from 'gatsby'

// class="

const Background = styled.div`
  ${tw`bg-grey absolute left-0 w-full`}
  height: 7vh;
  /* bottom: 5vh; */
  z-index: -10;
`

const FooterSection = styled.div`
  ${tw`w-full`}
  height: 7vh;
`
const TextLink = styled.span`
  ${tw`hover:text-blue ml-2`}
`

const Info = styled.span`
  ${tw`relative font-serif text-xl text-blue`}
  line-height: 0;
`
// class="

const P = styled.p`
  ${tw`font-sans text-sm mx-auto text-center p-4`}
`
export default () => (
  <FooterSection>
    <Background />
    <P>
      made with 💛 in Berlin {'   '}|
      <TextLink>
        <Link to="/impressum">Impressum</Link>
      </TextLink>
    </P>
  </FooterSection>
)
